<template>
  <div class="nfts w-100 h-100 d-flex flex-column align-items-center">
    <base-header
      title="NFTs"
      :is-title-bold="true"
      :left-arrow="true"
      :back-on-click-left="true"
      :breadcrumb="breadcrumb"
      :mode="$device.isMobile ? 'secondary' : 'primary'"
    ></base-header>

    <loading-wrapper v-if="isPageLoading"></loading-wrapper>

    <EmptyPlaceholder
      v-if="!isPageLoading && nfts.length === 0"
      icon="Go-nfts"
      :text="$t('目前沒有持有 NFT')"
    />
    <div v-else-if="nfts.length !== 0" class="wrapper scroll-bar">
      <div class="nfts-container">
        <NftCard
          v-for="nft in nfts"
          :key="nft.createdAt"
          :img="nft.image"
          :name="nft.name"
          :description="nft.description"
          @click.native="onClickNft(nft)"
        />
      </div>
    </div>
    <modal-confirm
      v-model="isTipsShow"
      :confirm-text="$t('知道了')"
      :hide-cancel="true"
      @confirm="isTipsShow = false"
    >
      {{ $t('預計於 11/18 揭曉 NFT 造型，') }}<br />
      {{ $t('敬請期待！') }}
    </modal-confirm>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import userService from '@/services/user';
import EmptyPlaceholder from '@/components/Base/EmptyPlaceholder.vue';
import LoadingWrapper from '@/components/Base/LoadingWrapper.vue';
import NftCard from '@/components/Personal/NftCard.vue';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';

export default {
  components: {
    BaseHeader,
    EmptyPlaceholder,
    LoadingWrapper,
    NftCard,
    ModalConfirm,
  },
  data() {
    return {
      nfts: [],
      isPageLoading: false,
      breadcrumb: [
        {
          name: this.$t('個人'),
          enable: true,
          router: 'personal',
        },
        {
          name: 'NFTs',
          enable: true,
          router: 'nfts',
        },
      ],
      isTipsShow: false,
    };
  },
  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    },
  },
  created() {
    this.getNfts();
  },
  methods: {
    async getNfts() {
      try {
        this.isPageLoading = true;

        const result = await userService.getNfts();
        this.nfts = result;
      } catch {
        this.$Message.error(this.$t('發生未知錯誤，再試一次'));
      } finally {
        this.isPageLoading = false;
      }
    },
    onClickNft(nft) {
      if (nft.isBlind) {
        this.isTipsShow = true;
      } else {
        this.$router.push({
          name: 'nft',
          params: {uuid: nft.uuid},
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nfts {
  .wrapper {
    width: 100%;
    height: calc(100vh - 56px);
    overflow: overlay;
  }
  .nfts-container {
    width: 100%;
    margin: 0 auto;
    padding: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: min-content;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }
}

@media screen and (min-width: 768px) {
  .nfts {
    .wrapper {
      height: calc(100vh - 80px);
    }
    .nfts-container {
      max-width: 720px;
      padding: 40px;
      margin: 40px auto;
      background: $white;
      border-radius: $rounded-md;
      box-shadow: 0px 2px 7px rgba(199, 199, 199, 0.3);
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 16px;
      grid-row-gap: 16px;
    }
  }
}

@media screen and (min-width: 1440px) {
  .nfts {
    .nfts-container {
      max-width: 1140px;
    }
  }
}
</style>
