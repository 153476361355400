<template>
  <div class="nft-card rounded-md overflow-hidden shadow-sm cursor-pointer">
    <div class="nft-img" :style="nftImg"></div>
    <div class="footer">
      <div class="name text-18">{{ name }}</div>
      <div class="description text-14 text-truncate">{{ description }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      offsetWidth: 0,
    };
  },
  computed: {
    nftImg() {
      return {
        height: `${this.offsetWidth}px`,
        backgroundImage: `url('${this.img}')`,
      };
    },
  },
  mounted() {
    this.offsetWidth = document.querySelector('.nft-card').offsetWidth;
  },
};
</script>

<style lang="scss" scoped>
.nft-card {
  width: 100%;
  .nft-img {
    background-size: cover;
    width: 100%;
  }
  .description {
    color: $font-grayscale-2;
  }
  .footer {
    padding: 12px;
  }
}
</style>
